import axios from "axios";
import { api } from "./interceptor";

export const getTableState = (accountId, tableGuid) => {
  return api.get(`/api/tablestate/${accountId}/${tableGuid}`);
}

export const setTableState = (data) => {
  return api.post(`/api/tablestate`, data);
}
